import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import BackToTop from "../components/back-to-top"
import { Container, Row, Col } from "reactstrap"
import { LazyLoadImage } from "react-lazy-load-image-component"

const PaymentOptions = ({ location }) => {
  // console.log(location)
  return (
    <Layout>
      <Seo title="Payment Options" />
      <div className="hero-adjustment pb-0">
        <div className="container">
          <div className="hero-content-wrapper">
            <div className="row no-gutters">
              <div className="col-12 col-md-7">
                <div className="hero-dog-bg">
                  <div className="dog-bg"></div>
                </div>
              </div>
              <div className="col-12 col-md-5">
                <div className="hero-video">
                  <div>
                    <div>
                      <div className="video-modal-btn no-video">
                        <LazyLoadImage
                          effect="blur"
                          src="/assets/img/tplo-video.jpg"
                          alt="video"
                          className="video-img img-fluid v-h"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-only-heading inner-page-title text-right arthroscopy-hero noVideoInner">
              <h1>Payment Options</h1>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="about-wrapper py-5"> */}
      <Container className="acl-container my-5">
        <Row className="border-none">
          <Col md={12}>
            <p>
              Colorado Canine Orthopedics accepts cash as well as Visa, MasterCard,
              American Express and Discover credit cards.
            </p>
            <h3>Financing Options</h3>
            <a
              href="https://www.carecredit.com/go/777RMH/"
              target="_blank"
              className="tel-cta"
              rel="noreferrer noopener"
            >
              <LazyLoadImage
                effect="blur"
                src="/assets/img/carecredit.jpg"
                alt="Care Credit"
                className="img-fluid mb-4"
                style={{ width: "264px", marginTop: "1.5rem" }}
              />
            </a>
            <p>
              <strong>Flexible Financing for pet care.</strong>
            </p>
            <p>We accept CareCredit, the pet health and wellness credit card with flexible, budget-friendly financing options that allow you to pay over time for a range of treatments and services.* From routine visits and preventative care to diagnostics and emergency surgeries, CareCredit can help you be prepared for any veterinary expense.</p>
            <p><a href="https://www.carecredit.com/go/777RMH/" target="_blank" rel="noreferrer noopener">Learn More</a></p>
            <p><small>* Subject to credit approval</small></p>
          </Col>
        </Row>
      </Container>
      <BackToTop />
      {/* </div> */}
    </Layout>
  )
}

export default PaymentOptions
